.logo {
    visibility: visible;
}
.hero {
    width: 100%;
    padding:0 4rem ;
}
.hero-content {
    display: grid;
    grid-template-columns: 50% 50%;
}
.hero-serv {
    background-color: #D9ECF7;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hero-serv img{
    width: 50%;
}


mark {
    position: relative;
    background-color: #fff;
  }
  mark:after {
    border: .1em solid yellow;
    border-top: none;
    border-radius: 1.5em 1em 2em 1.5em;
    bottom: -.3em;
    content: '';
    left: -.3em;
    position: absolute;
    right: -.3em;
    top: -.1em;
  }
  mark:before {
    border-top: .1em solid yellow;
    border-radius: .5em;
    content: '';
    height: 2em;
    left: 0;
    position: absolute;
    top: -.3em;
    transform: rotate(-10deg);
    width: 2em;
  }


.services {
    margin-top: 80px;
    background: #CEEDFF;
    display: flex;
    padding: 3rem 8rem;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}  


.service-card {
    display: grid;
    grid-template-columns: 60% 40%;
    width: 100%;
    /* justify-content: center;
    align-items: center; */
    justify-content: space-between;
}
.choose {
    margin-top: 80px;
    background: #CEEDFF;
    display: flex;
    padding: 3rem 8rem;
    align-items: center;
    flex-direction: column;
}
.prefooter {
    margin-top: 80px;
    background: #CEEDFF;
    padding: 3rem 8rem;
    height: 70vh;
}
.subscribe-input { 
    width: 60%;
    height: 40px;
    padding: 0 1.8rem;
}
.subscribe-input:focus{
    outline: none;
}
.subscribe-btn{
    background-color: #084DD6;
    color: #fff;
    height: 40px;
    width: 120px;
    cursor: pointer;
}
.footer {
    background-color: #084DD6;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
    padding: 3rem 8rem;
    color: #fff;
}
@media only screen and (max-width: 748px){
    .logo {
        visibility: hidden;
    }
    .hero {
        padding:0 3rem ;
    }
}

@media only screen and (max-width: 640px) {
    .logo {
        visibility: hidden;
    }
    .hero-content {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 3rem;
    }
    .hero {
        padding:0 2rem ;
    }
    .hero-serv {
        height: 40px;
        width: 40px;
    }
    .services {
        padding: 1rem 2rem;
    }
    .choose {
        padding: 1rem 2rem; 
    }
    .prefooter {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        padding: 1rem 2rem;
        align-items: center;
    }
    .subscribe-input { 
        width: 60%;
    }
    .subscribe-btn{
        background-color: #084DD6;
        color: #fff;
        height: 40px;
        width: 90px;
        cursor: pointer;
    }
    .footer {
        
        align-items: center;
        padding: 1rem 2rem;
    }
}