.swiper {
    width: 500px;
    height: 300px;
  }
  
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
  
  .swiper-slide:nth-child(1n) {
    background: url(../../assets/slide1.webp);
    object-fit: cover;
    color: #084DD6;
  }
  
  .swiper-slide:nth-child(2n) {
    background: url(../../assets/slide2.webp);
    object-fit: cover;
    color: #084DD6;
  }
  
  .swiper-slide:nth-child(3n) {
    background: url(../../assets/slide3.jpg);
    object-fit: cover;
    color: #084DD6;
  }
  
  .swiper-slide:nth-child(4n) {
    background: url(../../assets/slide4.jpg);
    object-fit: contain;
  }
  
  .swiper-slide:nth-child(5n) {
   background: url(../../assets/slide5.jpg);
   object-fit: contain;
  }
  
  /* .swiper-slide:nth-child(6n) {
    background-color: rgb(180, 10, 47);
  }
   */

@media only screen and (max-width: 640px) {
    .swiper {
        width: 350px;
        height: 250px;
        margin-bottom: 2rem;
      }
}