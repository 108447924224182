@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.bai-jamjuree-extralight {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.bai-jamjuree-light {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.bai-jamjuree-regular {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.bai-jamjuree-medium {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.bai-jamjuree-semibold {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.bai-jamjuree-bold {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.bai-jamjuree-extralight-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.bai-jamjuree-light {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 300;
  
}

.bai-jamjuree-regular-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.bai-jamjuree-medium-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.bai-jamjuree-semibold-italic {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.bai-jamjuree-bold {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 700;
 font-style: normal;
}


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
